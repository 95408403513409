<template>
  <button
    class="flex flex-row items-center justify-center bg-blue-700 hover:bg-blue-600 text-white py-2 px-4 ml-4 mb-4 rounded duration-300"
    @click="goBack">
    <img src="@/assets/arrow-back.svg" class="mr-2" /> Geri dön
  </button>
</template>

<script>
export default {
  name: "BackButton",
  methods: {
    goBack() {
      this.$emit("back");
    },
  },
};
</script>

<style scoped></style>
