<template>
  <span class="flex items-center text-gray-700 text-center font-semibold text-xl sm:text-xl md:text-2xl">
    {{ title }}
  </span>
</template>

<script>
export default {
  name: "TitleBar",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
