<template>
  <section class="relative w-full flex justify-center items-center z-50">
    <div class="w-[1024px] lg:mx-8 min-h-screen">
      <Spinner :is-visible="isFetching" />
      <div class="flex justify-between p-4 flex-col md:flex-row">
        <TitleBar :title="currentTitle" />
        <div v-if="showBooks">
          <div class="flex flex-col md:flex-row gap-4">
            <div class="flex flex-col mt-2 md:mt-0">
              <label for="search-bar" class="text-sm font-medium text-gray-700 flex items-center">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2 w-4 h-4">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z"
                      stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </g>
                </svg>
                Kitap Ara
              </label>
              <input type="text" v-model="searchQuery" placeholder="Kitap ismi ara..."
                class="mt-2 block w-full p-2 text-base border-slate-900 text-white bg-slate-900 sm:text-sm rounded-md" />
            </div>
            <div class="flex gap-4 items-end">
              <div class="flex flex-col">
                <label for="level-filter" class="text-sm font-medium text-gray-700 flex items-center">
                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2 w-4 h-4">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M3 4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H19.4C19.9601 3 20.2401 3 20.454 3.10899C20.6422 3.20487 20.7951 3.35785 20.891 3.54601C21 3.75992 21 4.03995 21 4.6V6.33726C21 6.58185 21 6.70414 20.9724 6.81923C20.9479 6.92127 20.9075 7.01881 20.8526 7.10828C20.7908 7.2092 20.7043 7.29568 20.5314 7.46863L14.4686 13.5314C14.2957 13.7043 14.2092 13.7908 14.1474 13.8917C14.0925 13.9812 14.0521 14.0787 14.0276 14.1808C14 14.2959 14 14.4182 14 14.6627V17L10 21V14.6627C10 14.4182 10 14.2959 9.97237 14.1808C9.94787 14.0787 9.90747 13.9812 9.85264 13.8917C9.7908 13.7908 9.70432 13.7043 9.53137 13.5314L3.46863 7.46863C3.29568 7.29568 3.2092 7.2092 3.14736 7.10828C3.09253 7.01881 3.05213 6.92127 3.02763 6.81923C3 6.70414 3 6.58185 3 6.33726V4.6Z"
                        stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                  </svg>
                  Seviye Filtresi
                </label>
                <select v-model="selectedLevel"
                  class="mt-2 block w-full p-2 text-base border-slate-900 text-white bg-slate-900 sm:text-sm rounded-md">
                  <option value="">Tüm Seviyeler</option>
                  <option v-for="level in levels" :key="level" :value="level">{{ level }}</option>
                </select>
              </div>
              <div class="flex flex-col">
                <label for="course-filter" class="text-sm font-medium text-gray-700 flex items-center">
                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2 w-4 h-4">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M3 4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H19.4C19.9601 3 20.2401 3 20.454 3.10899C20.6422 3.20487 20.7951 3.35785 20.891 3.54601C21 3.75992 21 4.03995 21 4.6V6.33726C21 6.58185 21 6.70414 20.9724 6.81923C20.9479 6.92127 20.9075 7.01881 20.8526 7.10828C20.7908 7.2092 20.7043 7.29568 20.5314 7.46863L14.4686 13.5314C14.2957 13.7043 14.2092 13.7908 14.1474 13.8917C14.0925 13.9812 14.0521 14.0787 14.0276 14.1808C14 14.2959 14 14.4182 14 14.6627V17L10 21V14.6627C10 14.4182 10 14.2959 9.97237 14.1808C9.94787 14.0787 9.90747 13.9812 9.85264 13.8917C9.7908 13.7908 9.70432 13.7043 9.53137 13.5314L3.46863 7.46863C3.29568 7.29568 3.2092 7.2092 3.14736 7.10828C3.09253 7.01881 3.05213 6.92127 3.02763 6.81923C3 6.70414 3 6.58185 3 6.33726V4.6Z"
                        stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                  </svg>
                  Ders Filtresi
                </label>
                <select v-model="selectedCourse"
                  class="mt-2 block w-full p-2 text-base border-slate-900 text-white bg-slate-900 sm:text-sm rounded-md">
                  <option value="">Tüm Dersler</option>
                  <option v-for="course in courses" :key="course" :value="course">{{ course }}</option>
                </select>
              </div>
              <button @click="resetFilters"
                class="flex flex-row items-center justify-center bg-blue-700 hover:bg-blue-600 p-1.5 text-white rounded duration-300 md:w-9 md:h-9 w-8 h-8">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor"
                  class="w-6 h-6">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M21 21L3 3V6.33726C3 6.58185 3 6.70414 3.02763 6.81923C3.05213 6.92127 3.09253 7.01881 3.14736 7.10828C3.2092 7.2092 3.29568 7.29568 3.46863 7.46863L9.53137 13.5314C9.70432 13.7043 9.7908 13.7908 9.85264 13.8917C9.90747 13.9812 9.94787 14.0787 9.97237 14.1808C10 14.2959 10 14.4182 10 14.6627V21L14 17V14M8.60139 3H19.4C19.9601 3 20.2401 3 20.454 3.10899C20.6422 3.20487 20.7951 3.35785 20.891 3.54601C21 3.75992 21 4.03995 21 4.6V6.33726C21 6.58185 21 6.70414 20.9724 6.81923C20.9479 6.92127 20.9075 7.01881 20.8526 7.10828C20.7908 7.2092 20.7043 7.29568 20.5314 7.46863L16.8008 11.1992"
                      stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="filteredBooks.length === 0 && !isFetching"
        class="mt-4 text-red-500 bg-red-200 rounded-lg py-2 px-4 flex gap-2">
        <img src="@/assets/error-icon.svg" alt="Error Icon" class="w-6 h-6" />
        <span>Arama kriterlerine göre kitap bulunamadı, lütfen filtreleri gözden geçirin.</span>
      </div>
      <div class="flex flex-row justify-between">
        <back-button v-if="!showBooks" @back="handleBack" class="m-4" />
        <report-button v-if="isVideoVisible && showReportButton" class="m-4" />
      </div>
      <QuestionTitleBar v-if="isVideoVisible" :title="currentQuestionTitle" :videoUrl="selectedVideoUrl"
        :questionNumber="currentQuestionIndex + 1" :answer="currentAnswer" :showAnswer="showAnswer"
        class="self-center" />
      <BackToTopButton class="z-50" />
      <div v-if="errorMessage && !isVideoVisible" class="flex items-center justify-center">
        <div
          class="text-red-500 bg-red-200 rounded-lg font-semibold py-2 px-4 flex flex-row text-nowrap items-center justify-center">
          <img src="@/assets/error-icon.svg" class="mr-2" /> {{ errorMessage }}
        </div>
      </div>
      <div v-else>
        <div class="container flex flex-col justify-end items-end">
          <video-player v-if="isVideoVisible && !errorMessage" :src="selectedVideoUrl" @close="closeVideoPlayer"
            class="w-full" />
          <VideoDownloadButton v-if="isVideoVisible && !errorMessage" :videoUrl="selectedVideoUrl"
            :questionNumber="currentQuestionIndex + 1" class="mt-4 mr-4 lg:mr-0" />
        </div>
        <div v-if="errorMessage && isVideoVisible" class="flex items-center justify-center mt-4 h-[384px]">
          <div
            class="text-red-500 bg-red-200 rounded-lg font-semibold py-2 px-4 flex flex-row text-nowrap items-center justify-center">
            <img src="@/assets/error-icon.svg" class="mr-2" />
            {{ errorMessage }}
          </div>
        </div>
        <div class="relative">
          <Spinner :is-visible="isFetching" />
          <ReportNotices v-if="isVideoVisible && showReportNotices && showReports" :reports="reports" />
        </div>
        <!-- Books -->
        <div v-if="showBooks">
          <div v-for="book in filteredBooks" :key="book.id" @click="showBookSections(book)"
            class="m-4 md:px-8 px-6 py-3 rounded-3xl cursor-pointer flex flex-col justify-between  duration-500 transition-all bg-gradient-to-r to-blue-500 via-gray-900 from-gray-900 bg-size-200 bg-pos-0 hover:bg-pos-100">
            <div
              class="text-md md:text-xl font-medium text-white flex justify-between items-center md:gap-4 gap-2 relative">
              <div class="flex flex-row items-center md:gap-4 gap-2">
                <img src="@/assets/book.svg" alt="Book Icon" />
                <span>{{ book.book_name }}</span>
              </div>
              <img class="min-w-8 min-h-8 h-8 w-8 absolute right-0 top-5" src="@/assets/arrow-right-2.svg"
                alt="Arrow Icon" />
            </div>
            <div v-if="showQuestionCount" class="mt-2 flex flex-row justify-between sm:justify-start">
              <div class="flex gap-1 w-1/2 md:w-1/4">
                <span class="text-orange-500"> {{ book.level }} </span>
                <span class="text-orange-500"> {{ book.course }} </span>
              </div>
              <div class="flex gap-1 w-1/2 md:w-full">
                <span class="text-orange-500">{{ book.totalQuestions }}</span>
                <span class="text-gray-500"> Soru </span>
              </div>
            </div>
          </div>
        </div>
        <!-- Sections -->
        <div v-if="showSections">
          <div v-for="section in sections" :key="section.id" @click="showBookTests(section)"
            class="m-4 md:px-8 px-6 py-3 rounded-3xl cursor-pointer flex flex-col justify-between  duration-500 transition-all bg-gradient-to-r to-blue-500 via-slate-900 from-slate-900 bg-size-200 bg-pos-0 hover:bg-pos-100">
            <div
              class="text-md md:text-xl font-medium text-white flex justify-between items-center md:gap-4 gap-2 relative">
              <div class="flex flex-row items-center md:gap-4 gap-2">
                <img src="@/assets/book.svg" alt="Book Icon" />
                <span>{{ section.title }}</span>
              </div>
              <img class="min-w-8 min-h-8 h-8 w-8 absolute right-0 top-5" src="@/assets/arrow-right-2.svg"
                alt="Arrow Icon" />
            </div>
            <div v-if="showQuestionCount" class="mt-2">
              <span class="text-gray-500"> Toplam Soru Sayısı: </span>
              <span class="text-orange-500">{{ section.totalQuestions }}</span>
            </div>
          </div>
        </div>
        <!-- Tests -->
        <div v-if="showTests">
          <div v-for="test in tests" :key="test.id" @click="showBookQuestions(test)"
            class="m-4 md:px-8 px-6 py-3 rounded-3xl cursor-pointer flex flex-col justify-between  duration-500 transition-all bg-gradient-to-r to-blue-500 via-slate-900 from-slate-900 bg-size-200 bg-pos-0 hover:bg-pos-100">
            <div
              class="text-md md:text-xl font-medium text-white flex justify-between items-center md:gap-4 gap-2 relative">
              <div class="flex flex-row items-center md:gap-4 gap-2">
                <img src="@/assets/book.svg" alt="Book Icon" />
                <span>{{ test.title }}</span>
              </div>
              <img class="min-w-8 md:min-h-8 h-8 w-8 absolute right-0 top-5" src="@/assets/arrow-right-2.svg"
                alt="Arrow Icon" />
            </div>
            <div v-if="showQuestionCount" class="mt-2 flex flex-col md:flex-row md:divide-x divide-gray-500">
              <div>
                <span class="text-gray-500"> Toplam Soru Sayısı: </span>
                <span class="text-orange-500">{{ test.totalQuestions }}</span>
              </div>
              <div class="md:ml-2 md:pl-2">
                <span class="text-gray-500"> Çözümlü Soru Sayısı: </span>
                <span class="text-orange-500">{{
                  test.totalRecordedQuestions
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Questions -->
        <div v-if="showQuestions && questions.length > 0">
          <div v-for="question in questions" :key="question.id" @click="
            handleQuestionClick(question)
            " :class="[
              'm-4 px-4 py-3 h-40 rounded-3xl flex justify-between items-center transition-all bg-gradient-to-r to-blue-500 via-slate-900 from-slate-900 bg-size-200 bg-pos-0 hover:bg-pos-100',
              {
                ' cursor-pointer': question.video_solution,
                'opacity-50 cursor-not-allowed': !question.video_solution,
              },
            ]">
            <div class="md:ml-4 ml-2 text-sm md:text-xl font-medium text-white flex items-center md:gap-4 gap-2">
              <img src="@/assets/book.svg" alt="Book Icon" />
              <span>{{ question.order_number }}. Soru</span>
            </div>
            <div class="flex items-center justify-center min-w-24 min-h-24 max-w-36 max-h-36">
              <img class="max-w-36 max-h-36 p-2 mr-14 md:mr-0" v-bind:src="question.question_image" />
            </div>
            <div class="md:min-w-[120px] flex justify-center">
              <img class="min-w-8 min-h-8 h-8 w-8" src="@/assets/play-icon.svg" alt="Play Icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, watch, provide, computed } from "vue";
import { useStore } from "vuex";
import api from "@/services/api";
import VideoPlayer from "./VideoPlayer.vue";
import TitleBar from "./TitleBar.vue";
import BackButton from "./BackButton.vue";
import Spinner from "./Spinner.vue";
import QuestionTitleBar from "./QuestionTitleBar.vue";
import ReportButton from "./ReportButton.vue";
import VideoDownloadButton from "./VideoDownloadButton.vue";
import ReportNotices from "./ReportNotices.vue";
import BackToTopButton from "./BackToTopButton.vue"

const store = useStore();
const tests = ref([]);
const books = ref([]);
const sections = ref([]);
const questions = ref([]);
const reports = ref([]);
const errorMessage = ref("");
const showBooks = ref(true);
const showTests = ref(false);
const showSections = ref(false);
const showQuestions = ref(false);
const isFetching = ref(false);
const currentBookTitle = ref("Kaynaklar");
const currentSectionTitle = ref("");
const currentTestTitle = ref("");
const currentTitle = ref(currentBookTitle.value);
const isVideoVisible = ref(false);
const selectedVideoUrl = ref("");
const currentQuestionIndex = ref(store.getters.currentQuestionIndex);
const currentQuestionTitle = ref("");
const currentAnswer = ref("");
const showAnswer = ref(false);
const showQuestionCount = process.env.VUE_APP_BOOK_LIST_SHOW_QUESTION_COUNT === "true";
const showReportButton = process.env.VUE_APP_SHOW_REPORT_BUTTON === "true";
const showReportNotices = process.env.VUE_APP_SHOW_REPORT_NOTICES === "true";
const showReports = ref(false);
const selectedLevel = ref('');
const levels = ref([]);
const selectedCourse = ref('');
const courses = ref([]);
const searchQuery = ref('');

const filteredBooks = computed(() => {
  return books.value.filter(book => {
    const levelMatch = !selectedLevel.value || book.level === selectedLevel.value;
    const courseMatch = !selectedCourse.value || book.course === selectedCourse.value;
    const nameMatch = !searchQuery.value ||
      book.book_name.toLocaleLowerCase('tr-TR').includes(searchQuery.value.toLocaleLowerCase('tr-TR'));
    return levelMatch && courseMatch && nameMatch;
  });
});

const resetFilters = () => {
  selectedLevel.value = '';
  selectedCourse.value = '';
  searchQuery.value = '';
};

provide("nextQuestion", async () => {
  if (currentQuestionIndex.value < questions.value.length - 1) {
    currentQuestionIndex.value++;
    store.dispatch("setCurrentQuestionIndex", currentQuestionIndex.value);
    const nextQuestion = questions.value[currentQuestionIndex.value];
    if (nextQuestion.video_solution) {
      await openVideoPlayer(
        nextQuestion.video_solution,
        currentQuestionIndex.value + 1,
        `${nextQuestion.order_number}. Soru` || "Belirsiz Soru",
        nextQuestion.id,
        nextQuestion.question_image
      );
    } else {
      currentQuestionTitle.value =
        `${nextQuestion.order_number}. Soru` || "Belirsiz Soru";
      errorMessage.value = `${nextQuestion.order_number}. soru için çözüm videosu bulunamadı.`;
      isVideoVisible.value = true;
      showQuestions.value = false;
      showAnswer.value = false;
      showReports.value = false;
      updateTitle();
    }
  }
});

provide("prevQuestion", async () => {
  if (currentQuestionIndex.value > 0) {
    currentQuestionIndex.value--;
    store.dispatch("setCurrentQuestionIndex", currentQuestionIndex.value);
    const prevQuestion = questions.value[currentQuestionIndex.value];
    if (prevQuestion.video_solution) {
      await openVideoPlayer(
        prevQuestion.video_solution,
        currentQuestionIndex.value + 1,
        `${prevQuestion.order_number}. Soru` || "Belirsiz Soru",
        prevQuestion.id,
        prevQuestion.question_image
      );
    } else {
      currentQuestionTitle.value =
        `${prevQuestion.order_number}. Soru` || "Belirsiz Soru";
      errorMessage.value = `${prevQuestion.order_number}. soru için çözüm videosu bulunamadı.`;
      isVideoVisible.value = true;
      showQuestions.value = false;
      showAnswer.value = false;
      showReports.value = false;
      updateTitle();
    }
  }
});

provide("questions", questions);
provide("currentQuestionIndex", currentQuestionIndex);

const updateTitle = () => {
  if (showBooks.value) {
    currentTitle.value = "Kaynaklar";
  } else if (showSections.value) {
    currentTitle.value = `${currentBookTitle.value}`;
  } else if (showTests.value) {
    currentTitle.value = `${currentBookTitle.value} / ${currentSectionTitle.value}`;
  } else if (showQuestions.value && !isVideoVisible.value) {
    currentTitle.value = `${currentBookTitle.value} / ${currentSectionTitle.value} / ${currentTestTitle.value}`;
  } else if (isVideoVisible.value) {
    currentTitle.value = `${currentBookTitle.value} / ${currentSectionTitle.value} / ${currentTestTitle.value}`;
  }
  store.dispatch("setCurrentBookTitle", currentBookTitle.value);
  store.dispatch("setCurrentSectionTitle", currentSectionTitle.value);
  store.dispatch("setCurrentTestTitle", currentTestTitle.value);
  store.dispatch("setCurrentQuestionTitle", currentQuestionTitle.value);
};

const handleBack = () => {
  if (errorMessage.value) {
    errorMessage.value = "";
    isVideoVisible.value = false;
    showQuestions.value = true;
    updateTitle();
  } else if (isVideoVisible.value) {
    isVideoVisible.value = false;
    showQuestions.value = true;
    currentQuestionTitle.value = "";
    updateTitle();
  } else if (showQuestions.value) {
    showQuestions.value = false;
    showTests.value = true;
    currentQuestionIndex.value = 0;
    updateTitle();
  } else if (showTests.value) {
    showTests.value = false;
    showSections.value = true;
    currentTestTitle.value = "";
    updateTitle();
  } else if (showSections.value) {
    showSections.value = false;
    showBooks.value = true;
    currentBookTitle.value = "Kaynaklar";
    updateTitle();
  }
};

watch(
  [
    showBooks,
    showSections,
    showTests,
    showQuestions,
    isVideoVisible,
    currentQuestionIndex,
  ],
  updateTitle
);

const getBooks = async () => {
  try {
    isFetching.value = true;
    const response = await api.get(`${process.env.VUE_APP_API_URL}my-tasks-v3`);
    books.value = response.data.data.map((book) => {
      const totalQuestions = book.sections.reduce(
        (sum, section) => sum + section.total_number_of_questions,
        0
      );
      return { ...book, totalQuestions };
    });
    const uniqueLevels = new Set(books.value.map(book => book.level));
    levels.value = Array.from(uniqueLevels);
  } catch (error) {
    console.error("Kitaplar getirilirken hata oluştu:", error);
    errorMessage.value = "Kitaplar getirilirken hata oluştu.";
  } finally {
    isFetching.value = false;
  }
};

const showBookSections = async (book) => {
  isFetching.value = true;
  sections.value =
    book.sections.map((section) => {
      const totalQuestions = section.total_number_of_questions || 0;
      return { ...section, totalQuestions };
    }) || [];
  showSections.value = true;
  showBooks.value = false;
  currentBookTitle.value = book.book_name;
  currentSectionTitle.value = "";
  currentTestTitle.value = "";
  currentQuestionIndex.value = 0;
  updateTitle();
  isFetching.value = false;
};

const showBookTests = async (section) => {
  const url = `my-task-section/${section.id}`;
  try {
    isFetching.value = true;
    const response = await api.get(url);
    tests.value =
      response.data.data.tests.map((test) => {
        const totalQuestions = test.total_number_of_questions || 0;
        const totalRecordedQuestions = test.total_recorded_questions || 0;
        return { ...test, totalQuestions, totalRecordedQuestions };
      }) || [];
    showSections.value = false;
    showTests.value = true;
    currentSectionTitle.value = section.title;
    currentTestTitle.value = "";
    currentQuestionIndex.value = 0;
    updateTitle();
  } catch (error) {
    console.error("Testler getirilirken hata oluştu:", error);
    errorMessage.value = "Testler getirilirken hata oluştu.";
  } finally {
    isFetching.value = false;
  }
};

const showBookQuestions = async (test) => {
  const url = `my-task-test/${test.id}`;

  try {
    isFetching.value = true;

    const response = await api.get(url);
    if (
      response.data.data.question_tasks &&
      response.data.data.question_tasks.length > 0
    ) {
      questions.value = response.data.data.question_tasks.map(
        (question, index) => {
          return {
            ...question,
            order_number: index + 1,
            question_image: question.question_image
          };
        }
      );
      showTests.value = false;
      showQuestions.value = true;
      currentTestTitle.value = test.title;
      currentQuestionIndex.value = 0;
      store.dispatch("setCurrentQuestionIndex", currentQuestionIndex.value);
      errorMessage.value = "";
      updateTitle();
      await fetchReportsAndAnswer();
    } else {
      questions.value = [];
      showTests.value = true;
      showQuestions.value = false;
      currentTestTitle.value = test.title;
      errorMessage.value = "Bu testte hiç soru bulunamadı!";
      updateTitle();
    }
  } catch (error) {
    console.error("Sorular getirilirken hata oluştu:", error);
    errorMessage.value = "Sorular getirilirken hata oluştu.";
  } finally {
    isFetching.value = false;
  }
};

const openVideoPlayer = async (
  videoUrl,
  questionIndex,
  questionTitle,
  questionId,
  questionImage
) => {
  if (!videoUrl) return;

  currentQuestionIndex.value = questionIndex - 1;
  store.dispatch("setCurrentQuestionIndex", currentQuestionIndex.value);
  store.dispatch("setCurrentQuestionId", questionId);
  store.dispatch("setCurrentQuestionImage", questionImage);

  currentQuestionTitle.value = questionTitle
    ? `${questionIndex}. Soru`
    : "Belirsiz Soru";
  selectedVideoUrl.value = videoUrl;
  errorMessage.value = "";
  isVideoVisible.value = true;
  showQuestions.value = false;
  await fetchReportsAndAnswer();
  updateTitle();
};

const handleQuestionClick = async (question) => {
  if (question.video_solution) {
    await openVideoPlayer(
      question.video_solution,
      question.order_number,
      question.question_order_number ? `${question.order_number}. Soru` : 'Belirsiz Soru',
      question.id,
      question.question_image
    );
  }
};

const closeVideoPlayer = () => {
  isVideoVisible.value = false;
  currentQuestionTitle.value = "";
  showQuestions.value = true;
  updateTitle();
};

const fetchReportsAndAnswer = async () => {
  const currentQuestion = questions.value[currentQuestionIndex.value];
  if (!currentQuestion) return;
  const newApiUrl = `my-task-question/${currentQuestion.id}`;

  try {
    isFetching.value = true;
    const newApiResponse = await api.get(newApiUrl);
    reports.value = newApiResponse.data.data.error_reports;
    currentAnswer.value = newApiResponse.data.data.answer;
    showAnswer.value = true;
    showReports.value = !!newApiResponse.data.data.video_solution;
  } catch (error) {
    console.error(
      "Hata bildirimleri ve cevap getirilirken hata oluştu:",
      error
    );
  } finally {
    isFetching.value = false;
  }
};

onMounted(async () => {
  await getBooks();
  const uniqueCourses = new Set(books.value.map(book => book.course));
  courses.value = Array.from(uniqueCourses);
});

</script>

<style scoped></style>
