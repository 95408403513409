<template>
  <section class="md:min-h-screen flex md:items-center justify-center relative z-50 mx-4">
    <div
      class="min-h-[500px] lg:bg-blue-800 bg-slate-200 bg-opacity-50 bg-clip-padding backdrop-filter backdrop-blur-md lg:bg-opacity-10 border border-gray-100 flex items-center rounded-2xl shadow-lg max-w-sm my-8 mx-2 md:max-w-3xl md:p-5 md:m-5">
      <div class="w-1/2 md:block hidden">
        <img class="rounded-2xl" src="@/assets/login.webp" alt="" />
      </div>
      <div class="md:w-1/2 px-4 md:px-8">
        <h2 class="font-bold text-2xl text-gray-700 md:px-0 px-4">
          Hesabınıza giriş yapın
        </h2>
        <p class="text-sm mt-4 text-gray-700 md:px-0 px-4">
          Soruların çözüm videolarını kolaylıkla izlemek için giriş yapın.
        </p>

        <form @submit.prevent="onLogin" class="flex flex-col gap-4">
          <input class="px-4 py-2 mt-8 rounded-xl border shadow md:mx-0 mx-4 focus:border-orange-500" type="email"
            name="email" placeholder="E-posta" v-model="email" />
          <div class="error px-4 text-red-500" v-if="errors.email">
            {{ errors.email }}
          </div>
          <div class="relative">
            <input :type="showPassword ? 'text' : 'password'"
              class="px-4 py-2 rounded-xl border md:w-full shadow md:mx-0 mx-4 focus:border-orange-500" name="password"
              placeholder="••••••••" v-model="password" />
            <div class="error px-4 text-red-500" v-if="errors.password">
              {{ errors.password }}
            </div>
            <svg v-if="!showPassword" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray"
              class="bi bi-eye absolute top-1/2 md:right-3 right-8 -translate-y-1/2 cursor-pointer" viewBox="0 0 16 16"
              @click="togglePasswordVisibility">
              <path
                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
            </svg>
            <svg v-if="showPassword" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray"
              class="bi bi-eye-slash-fill absolute top-1/2 md:right-3 right-8 -translate-y-1/2 cursor-pointer"
              viewBox="0 0 16 16" @click="togglePasswordVisibility">
              <path
                d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
              <path
                d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
            </svg>
          </div>
          <div class="error px-4 text-red-500" v-if="errors.general">
            {{ errors.general }}
          </div>
          <button type="submit"
            class="rounded-xl text-white md:mx-0 mx-4 py-2 hover:scale-105 duration-500 transition-all bg-gradient-to-r to-blue-500 via-gray-900 from-gray-900 bg-size-200 bg-pos-0 hover:bg-pos-200">
            Giriş yap
          </button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import LoginValidations from "@/services/LoginValidations";
import { mapActions } from "vuex";
import { useRouter } from "vue-router";

export default {
  data() {
    return {
      email: "",
      password: "",
      errors: {},
      showPassword: false,
    };
  },
  methods: {
    ...mapActions(["login"]),
    async onLogin() {
      let validations = new LoginValidations(this.email, this.password);
      this.errors = validations.checkValidations();
      if (Object.keys(this.errors).length === 0) {
        try {
          const formData = new FormData();
          formData.append("email", this.email);
          formData.append("password", this.password);

          const response = await axios.post(
            "https://test-backend.indivibook.com/api/v2/login",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (
            response.data &&
            response.data.success &&
            response.data.success.token
          ) {
            await this.login(response.data.success.token);
            this.$router.push({ name: "List" });
          } else {
            this.errors.general = "Giriş başarısız. Lütfen tekrar deneyin.";
          }
        } catch (error) {
          console.error("Login failed:", error);
          if (error.response && error.response.data) {
            this.errors.general =
              error.response.data.message ||
              "Giriş başarısız. Lütfen tekrar deneyin.";
          } else {
            this.errors.general = "Giriş başarısız. Lütfen tekrar deneyin.";
          }
        }
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
  mounted() {
    this.$router = useRouter();
  },
};
</script>

<style lang="scss" scoped></style>
