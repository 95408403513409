<template>
  <button
    class="flex flex-row items-center justify-center bg-red-700 hover:bg-red-600 text-white py-2 px-4 ml-4 mb-4 rounded hover:scale-105 duration-300"
    @click="goToReportForm">
    <img src="@/assets/report-icon.svg" class="mr-2" /> Sorun bildir
  </button>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const router = useRouter();

const goToReportForm = () => {
  const currentQuestionId = store.getters.currentQuestionId;
  router.push({ name: "Report", query: { questionId: currentQuestionId } });
};

</script>

<style scoped></style>
