<template>
  <video ref="videoPlayer" class="video-player vjs-big-play-centered !bg-slate-200 rounded-b-xl shadow-lg"
    :src="videoSrc" crossorigin="anonymous" playsinline controls :volume="0.5" :height="400">
    <source :src="videoSrc" type="video/mp4">
    Your browser does not support the video tag.
  </video>
</template>

<script setup>
import { onMounted, ref, onUnmounted } from 'vue';
import "video.js/dist/video-js.css";

defineProps({
  videoSrc: String,
});

const videoPlayer = ref(null);

onMounted(() => {
  const handleKeydown = (event) => {
    if (event.key === 'f') {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        videoPlayer.value.requestFullscreen();
      }
    } else if (event.key === 'm') {
      videoPlayer.value.muted = !videoPlayer.value.muted;
    }
  };

  window.addEventListener('keydown', handleKeydown);

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeydown);
  });
});
</script>

<style>
.video-player .vjs-progress-control {
  display: block !important;
}

.video-player .vjs-live-control {
  display: none !important;
}
</style>
