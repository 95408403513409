<template>
    <button v-if="isVisible" @click="scrollToTop" type="button" data-twe-ripple-init data-twe-ripple-color="light"
        class="fixed bottom-5 right-5 rounded-full bg-blue-600 p-4 md:p-4 text-xs font-medium uppercase leading-tight text-white shadow-md transition-all duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg animate-bounce "
        id="btn-back-to-top">
        <span class="[&>svg]:w-4 md:[&>svg]:w-6">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18" />
            </svg>
        </span>
    </button>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const isVisible = ref(false);

const toggleVisibility = () => {
    isVisible.value = window.scrollY > 300;
};

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

onMounted(() => {
    window.addEventListener('scroll', toggleVisibility);
});

onUnmounted(() => {
    window.removeEventListener('scroll', toggleVisibility);
});
</script>

<style scoped>
/* Additional styles can go here if needed */
</style>